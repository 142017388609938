import React, { useState } from 'react';
import HeaderLine from '../components/header';
import Workdropdown from '../components/workdropdown';
import Footer from '../components/footer';
import "../App.css"

export default function About() {
    const hobbies = [
        "running around my backhand on the tennis court", 
        "reading and talking about books with my friends",
        "blundering my pieces in chess",
        "going to the gym",
        "having irrational discussions about basketball",
        "theorizing the newest one piece chapter",
        "updating my spotify playlists",
        "eating pastries",
        "filling the sketchbook"
    ];
    
    const [currentIndex, setCurrentIndex] = useState(Math.floor(Math.random() * hobbies.length));
    const currentValue = hobbies[currentIndex];

    const handleTextClick = () => {
        const nextIndex = (currentIndex + 1) % hobbies.length;
        setCurrentIndex(nextIndex);
    };

    return (
        <div className="page-padding">
            <HeaderLine className="page-header" header="about"/>

            <div className="about-container">
                {/* Left side: About text */}
                <div className="about-text">
                    <p>I graduated from Northeastern University in 2022 and am now a developer based in nyc.
                        I'm interested in building full-stack applications I think are cool and useful.</p>
                   
                    <p>
                        In my free time, I enjoy: <span className="link-text" onClick={handleTextClick}>{currentValue}</span>
                    </p>
                    <ul>
                        <li> Currently reading <a className="link-text" href="https://www.goodreads.com/book/show/11.The_Hitchhiker_s_Guide_to_the_Galaxy" target="_blank" rel="noopener noreferrer">words</a> </li>
                        <li> Currently listening to <a className="link-text" href="https://open.spotify.com/playlist/37i9dQZF1EpiAEeSvCqsnR?si=20d02af402e544a6" target="_blank" rel="noopener noreferrer">sounds </a></li>
                    </ul>
                </div>

                {/* Right side: Work history */}
                <div className="work-history">
                    <Workdropdown />
                    <p>Here's my <a className="contact-link" href="https://www.linkedin.com/in/ariangokhale/" target="_blank" rel="noopener noreferrer"> LinkedIn</a> if you want to connect</p>
                </div>
            </div>
            <Footer />
        </div>
    );
}
